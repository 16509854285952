<template>
  <tr>
    <td class="nowrap-column">
      <strong>{{ branch.title }}</strong>
      <br/>
      <small>{{ branch.address }}</small>
    </td>
    <td class="nowrap-column">
      <strong>{{ branch.city.name }}</strong>
    </td>
    <td class="nowrap-column">
      <small v-if="branch.contact_details.phone_one">
        Телефон #1: <strong class="font-medium">{{ branch.contact_details.phone_one }}</strong>
      </small>
      <small v-if="branch.contact_details.phone_two">
        <br/>
        Телефон #2: <strong class="font-medium">{{ branch.contact_details.phone_two }}</strong>
      </small>
      <small v-if="branch.contact_details.phone_three">
        <br/>
        Телефон #3: <strong class="font-medium">{{ branch.contact_details.phone_three }}</strong>
      </small>
      <small v-if="branch.contact_details.email">
        <br/>
        Email: <strong class="font-medium">{{ branch.contact_details.email }}</strong>
      </small>
      <small v-if="branch.contact_details.instagram_link">
        <br/>
        <a :href="branch.contact_details.instagram_link" target="_blank">
          <strong class="font-medium">Instagram</strong>
        </a>
      </small>
      <small v-if="branch.contact_details.telegram_link">
        <br/>
        <a :href="branch.contact_details.telegram_link" target="_blank">
          <strong class="font-medium">Telegram</strong>
        </a>
      </small>
    </td>
    <td>
      {{ branch.cars_count }}
    </td>
    <td class="nowrap-column">
      {{ branch.updated_date }}
    </td>
    <td class="nowrap-column">
      <router-link :to="{
                            name: 'branch.edit',
                            params: {
                                branchId: branch.id
                            }
                         }"
                   class="btn btn-primary"
                   style="margin: 3px 2px 2px 3px"
                   title="Редактировать">
        <i class="fa fa-edit"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";
import VuejsDialog from 'vuejs-dialog';

Vue.use(VuejsDialog);

export default {
  name: 'branch-item',

  props: {
    branch: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  methods: {}
}
</script>

<style scoped>

</style>
